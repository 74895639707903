/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.marquee-text {
  animation: marquee 30s linear infinite;
}

body {
  margin: 0;
  /* Keep existing font styles or adjust as needed */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Your base styles */
@layer base {
  html {
    /* Default font family for entire HTML document */
    font-family: "Source Sans Pro", Arial, sans-serif;
  }

  .font-fjalla-one {
    /* Applying Fjalla One font family */
    font-family: "Fjalla One", Arial, sans-serif;
  }
}
